/* eslint-disable @typescript-eslint/ban-types */
import * as Yup from 'yup';
import ipRegex from 'ip-regex';

export {
    default as ContactFormSchema,
    init as ContactFormSchemaInit,
} from './ContactForm';

export {
    default as UserLoginResetSchema,
    init as UserLoginResetSchemaInit,
} from './UserLoginReset';

export { default as LoginSchema, init as LoginSchemaInit } from './Login';
export {
    default as PasswordResetSchema,
    init as PasswordResetSchemaInit,
} from './PasswordReset';

export {
    default as PasswordChangeSchema,
    init as PasswordChangeSchemaInit,
} from './PasswordChange';

export {
    default as EmailChangeSchema,
    init as EmailChangeSchemaInit,
} from './EmailChange';

export { default as ProfileSchema, init as ProfileSchemaInit } from './Profile';

export {
    default as RegistrationSchema,
    init as RegistrationSchemaInit,
} from './Registration';

export {
    default as MicroKeywordsSchema,
    init as MicroKeywordsSchemaInit,
} from './MicroKeywords';

export {
    default as MacroProjectsSchema,
    init as MacroProjectsSchemaInit,
} from './MacroProjects';

export type SchemaType = {
    [key: string]:
        | Yup.StringSchema<string | undefined, object>
        | Yup.NumberSchema<number | undefined, object>
        | Yup.BooleanSchema<boolean | null | undefined, object>;
};

Yup.addMethod(Yup.string, 'ipv4', function (message) {
    return this.test('ipv4', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex.v4({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IPv4 address' })
        );
    });
});

Yup.addMethod(Yup.string, 'ipv6', function (message) {
    return this.test('ipv6', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex.v6({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IPv6 address' })
        );
    });
});

Yup.addMethod(Yup.string, 'ip', function (message) {
    return this.test('ip', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IP address' })
        );
    });
});

export { Yup };
