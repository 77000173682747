import { PageComponent } from '@root/pages/Type';
import styles from '../Index.module.scss';

const Index: PageComponent = () => {
    return (
        <div className={styles.success_animation}>
            <svg
                className={styles.checkmark_info}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
            >
                <circle
                    className={styles.checkmark__info_circle}
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                />
                <line
                    className={styles.checkmark__info}
                    strokeWidth="4"
                    id="svg_3"
                    y2="33.12513"
                    x2="26"
                    y1="10.79172"
                    x1="26"
                    fill="none"
                />
                <ellipse
                    className={styles.checkmark__info}
                    strokeWidth="2"
                    ry="1.25"
                    rx="1.25"
                    cy="39.12495"
                    cx="26"
                    fill="none"
                />
            </svg>
        </div>
    );
};

export default Index;
