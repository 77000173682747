import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        contactName: Yup.string().required(
            t('form.errors.validation.contactName.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        contactEmail: Yup.string()
            .required(
                t('form.errors.validation.contactEmail.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .email(
                t('form.errors.contactEmail.email', {
                    defaultValue:
                        'Privalote nurodyti tinkama el. pašto adresą.',
                }),
            ),
        contactMessage: Yup.string()
            .required(
                t('form.errors.validation.contactMessage.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .min(
                20,
                t('form.errors.validation.contactMessage.min', {
                    defaultValue:
                        'Žinutė privalo būti sudarytas mažiausiai iš 20 simbolių.',
                }),
            ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
