/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PageComponent } from '@root/pages/Type';
import { useTranslation } from 'react-i18next';
import Noty from '@root/utils/Noty';
import classNames from 'classnames';
import { useCopyToClipboard } from 'react-use';
import IconSvg from '@static/img/icons/copy.svg';
import styles from './CopyIcon.module.scss';

type CopyIconProps = {
    value: string;
    className?: string;
};

const CopyIcon: PageComponent<CopyIconProps> = ({ className, value }) => {
    const { t } = useTranslation('uiCopyIcon');
    const [state, copyToClipboard] = useCopyToClipboard();
    return (
        <IconSvg
            className={classNames(className, styles.icon)}
            onClick={(e) => {
                e.preventDefault();
                copyToClipboard(value);
                if (state.error) {
                    Noty({
                        title: t('noty.error.title', {
                            defaultValue: 'Kopijavimas',
                        }),
                        message: t('noty.error.message', {
                            defaultValue: 'Nepavyko nukopijuoti.',
                        }),
                        type: 'danger',
                    });
                    return;
                }
                Noty({
                    title: t('noty.title', {
                        defaultValue: 'Kopijavimas',
                    }),
                    message: t('noty.message', {
                        defaultValue: 'Sėkmingai nukopijuota.',
                    }),
                    type: 'success',
                });
            }}
        />
    );
};
export default CopyIcon;
