import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';
import TwoAuthValidation from '@components/TwoAuth/Validation';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        ...TwoAuthValidation(),
        passwordNew: Yup.string()
            .required(
                t('form.errors.validation.passwordNew.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .min(
                3,
                t('form.errors.validation.passwordNew.min', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas mažiausiai iš 3 simbolių.',
                }),
            )
            .max(
                64,
                t('form.errors.validation.passwordNew.max', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas daugiausiai iki 64 simbolių.',
                }),
            )
            .matches(
                /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                t('form.errors.validation.passwordNew.strong', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas bent iš vienos mažosios raidės, didžiosios raidės ir skaičiaus.',
                }),
            ),
        passwordNewConfirm: Yup.string()
            .required(
                t('form.errors.validation.passwordNewConfirm.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .oneOf(
                [Yup.ref('passwordNew')],
                t('form.errors.validation.passwordNewConfirm.match', {
                    defaultValue: 'Slaptažodžiai privalo sutapti.',
                }),
            ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
