import { CaptchaProvider } from '@components/CaptchaProvider';
import { PageComponent } from '@root/pages/Type';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import GoogleAnalytics from '../GoogleAnalytics';
import { LiveHelpProvider } from '../LiveHelpContext';

const LiveHelpNoSSR = dynamic(() => import('../LiveHelp'), { ssr: false });

const Layout: PageComponent = ({ children }) => {
    const { publicRuntimeConfig } = getConfig();
    return (
        <>
            <LiveHelpProvider>
                <CaptchaProvider>{children}</CaptchaProvider>
                <LiveHelpNoSSR />
            </LiveHelpProvider>
            {publicRuntimeConfig.GOOGLE_ANALYTICS ? (
                <GoogleAnalytics ga_id={publicRuntimeConfig.GOOGLE_ANALYTICS} />
            ) : null}
        </>
    );
};

Layout.displayName = 'MainLayout';

export default Layout;
