import { PageComponent } from '@root/pages/Type';
import React from 'react';

const withLayout = <P extends {}>(
    Layout: PageComponent,
    WrappedComponent: PageComponent<P>,
): PageComponent<P> => {
    const Wrapper: PageComponent<P> = (props) => (
        <Layout>
            <WrappedComponent {...props} />
        </Layout>
    );
    Wrapper.displayName = `withLayout(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    return Wrapper;
};

export default withLayout;
