import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        project: Yup.string().required(
            t('schema.macro_projects.project.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        password: Yup.string()
            .required(
                t('schema.macro_projects.password.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .min(
                3,
                t('schema.macro_projects.password.min', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas mažiausiai iš 3 simbolių.',
                }),
            )
            .max(
                64,
                t('schema.macro_projects.password.max', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas daugiausiai iki 64 simbolių.',
                }),
            )
            .matches(
                /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                t('schema.macro_projects.password.strong', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas bent iš vienos mažosios raidės, didžiosios raidės ir skaičiaus.',
                }),
            ),
        link: Yup.string().required(
            t('schema.macro_projects.link.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
