import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        email: Yup.string()
            .required(
                t('schema.registration.email.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .email(
                t('schema.registration.email.email', {
                    defaultValue:
                        'Privalote nurodyti tinkama el. pašto adresą.',
                }),
            )
            .max(
                64,
                t('schema.registration.email.max', {
                    defaultValue:
                        'Laukelis gali turėti daugiausiai 64 simbolius.',
                }),
            ),
        password: Yup.string()
            .required(
                t('schema.registration.password.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .min(
                6,
                t('schema.registration.password.min', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas mažiausiai iš 6 simbolių.',
                }),
            )
            .max(
                64,
                t('schema.registration.password.max', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas daugiausiai iki 64 simbolių.',
                }),
            )
            .matches(
                /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                t('schema.registration.password.strong', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas bent iš vienos mažosios raidės, didžiosios raidės ir skaičiaus.',
                }),
            ),
        // country: Yup.number()
        //     .required(
        //         t('schema.registration.country.required', {
        //             defaultValue: 'Pasirinkimas privalomas.',
        //         }),
        //     )
        //     .min(
        //         1,
        //         t('schema.registration.country.required', {
        //             defaultValue: 'Pasirinkimas privalomas.',
        //         }),
        //     ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
