// TODO: Add images alt tags
/* eslint-disable jsx-a11y/alt-text */
import { PageComponent } from '@root/pages/Type';
import { setLanguage } from '@root/store/i18n';
import { AppDispatch, RootState } from '@root/store/types';
import { setCookie } from '@root/utils/cookie';
import CloseIcon from '@static/img/icons/close.svg';
import Menu from '@static/img/icons/menu.svg';
import Flags from 'country-flag-icons/react/3x2';
import { useRouter } from 'next/router';
import {
    MouseEvent,
    ReactElement,
    Ref,
    forwardRef,
    useCallback,
    useMemo,
} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from '../router';
import Link, { LinkParamsProps } from './Link';
import styles from './Navigation.module.scss';
import LinkToAuthenticate from './Auth/LinkToAuthenticate';

type CustomToggleProps = {
    children?: ReactElement | string;
    onClick?: (event: MouseEvent) => void;
};

const CustomToggle = forwardRef<HTMLAnchorElement, CustomToggleProps>(
    ({ children, onClick }, ref: Ref<HTMLAnchorElement>): ReactElement => (
        <a
            className="btn btn-link dropdown-toggle"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                if (onClick) onClick(e);
            }}
        >
            {children}
        </a>
    ),
);

CustomToggle.displayName = 'CustomToggle';

const languages = [
    {
        short: 'lt',
        value: 'Lietuvių',
        icon: () => <Flags.LT title="Lietuvių" />,
    },
    {
        short: 'en',
        value: 'English',
        icon: () => <Flags.US title="English" />,
    },
    /*
    {
        short: 'ru',
        value: 'Русский',
        icon: 'ru',
    },
    */
];

const UserLoginButton: PageComponent<{
    showButtons: boolean;
}> = ({ showButtons }) => {
    if (!showButtons) {
        return null;
    }
    return (
        <LinkToAuthenticate href="auth_login">
            <a className={`btn btn-tertiary ${styles.nav_user}`}>
                <span>
                    <Trans i18nKey="clientZoneButton" ns="landingNavigation">
                        Klientų zona
                    </Trans>
                </span>
            </a>
        </LinkToAuthenticate>
    );
};

const UserRegisterButton: PageComponent<{
    isOpen: boolean;
    showButtons: boolean;
}> = ({ isOpen, showButtons }) => {
    const authedUser = useSelector((state: RootState) => state.auth.user);
    if (authedUser) {
        return null;
    }
    if (!isOpen || !showButtons) {
        return null;
    }
    return (
        <LinkToAuthenticate href="auth_registration">
            <a className={`btn btn-primary ${styles.nav_register}`}>
                <Trans i18nKey="registrationButton" ns="landingNavigation">
                    Registracija
                </Trans>
            </a>
        </LinkToAuthenticate>
    );
};

const LanguageSwitcher: PageComponent = () => {
    const router = useRouter();
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    const dispatch = useDispatch<AppDispatch>();
    const choosedLanguage = useMemo(
        () => languages.find((language) => language.short === currentLanguage),
        [languages, currentLanguage],
    );

    const handleLanguageOnClick = useCallback(
        async (lang: string) => {
            if (!choosedLanguage) {
                return;
            }
            setCookie('next-i18next', lang);
            dispatch(setLanguage(lang));
            const { nextRoute, ...params } = router.query;
            await Router.pushRoute(nextRoute as string, params, lang, {
                locale: lang,
            });
        },
        [choosedLanguage, dispatch, setLanguage, router, currentLanguage],
    );

    if (!currentLanguage || !choosedLanguage) {
        return null;
    }
    return (
        <Dropdown className={styles.nav_lang}>
            <Dropdown.Toggle as={CustomToggle} id="nav_lang">
                {choosedLanguage.short.toLocaleUpperCase()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {languages
                    .filter((language) => language.short !== currentLanguage)
                    .map((language) => (
                        <Dropdown.Item
                            key={`select-language-${language.short}`}
                            onClick={(event) => {
                                event.preventDefault();
                                handleLanguageOnClick(language.short);
                            }}
                            className={styles.langItem}
                        >
                            <span className={`${styles.flag}`}>
                                {language.icon()}
                            </span>
                            {language.value}
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const CloseLink: PageComponent<{
    href: string;
    params?: LinkParamsProps;
}> = ({ href, params }) => {
    return (
        <Link href={href} params={params}>
            <a className={`navbar-toggler ${styles.navbar_toggler}`}>
                <CloseIcon />
            </a>
        </Link>
    );
};

const CloseButton: PageComponent<{
    isOpen?: boolean;
    close?: () => void;
    open?: () => void;
}> = ({ isOpen, close, open }) => {
    const toggleNavigation = (
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
        event.preventDefault();
        if (isOpen) {
            if (close) {
                close();
            }
        } else {
            if (open) {
                open();
            }
        }
    };

    if (typeof isOpen === 'undefined') {
        return null;
    }

    return (
        <button
            className={`navbar-toggler ${styles.navbar_toggler}`}
            type="button"
            onClick={toggleNavigation}
        >
            {!isOpen ? <Menu /> : <CloseIcon />}
        </button>
    );
};

type NavigationHeaderProps = {
    isOpen: boolean;
    open?: () => void;
    close?: () => void;
    showUseButtons?: boolean;
    closeHref?: string;
    closeParams?: LinkParamsProps;
};

const NavigationHeader: PageComponent<NavigationHeaderProps> = ({
    isOpen,
    open,
    close,
    showUseButtons = true,
    closeHref,
    closeParams,
}) => {
    return (
        <nav className={`navbar navbar-light ${styles.navbar} container`}>
            <div className={styles.navbar_inner}>
                <Link href="landing_home">
                    <a className={`navbar-brand ${styles.navbar_brand}`}>
                        <img src="/static/img/logos/logo.svg" alt="" />
                    </a>
                </Link>

                {closeHref ? (
                    <CloseLink href={closeHref} params={closeParams} />
                ) : (
                    <CloseButton isOpen={isOpen} close={close} open={open} />
                )}

                <div className={`${styles.nav_extra}`}>
                    <LanguageSwitcher />
                    <UserRegisterButton
                        isOpen={isOpen}
                        showButtons={showUseButtons}
                    />
                    <UserLoginButton showButtons={showUseButtons} />
                </div>
            </div>
        </nav>
    );
};

export default NavigationHeader;
