import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        keyword: Yup.string().required(
            t('schema.micro_keywords.keyword.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        password: Yup.string().required(
            t('schema.micro_keywords.password.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        link: Yup.string().required(
            t('schema.micro_keywords.link.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        returnText: Yup.string().required(
            t('schema.micro_keywords.returnText.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        )
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
