import { PageComponent } from '@root/pages/Type';
import { RootState } from '@root/store/types';
import { useSelector } from 'react-redux';
import Link, { LinkParamsProps, LinkProps } from '../Link';
import { useRouter } from 'next/router';
import { RouteHref } from '@root/router';
import { useMemo } from 'react';

const LinkToAuthenticate: PageComponent<
    {
        href: string;
        params?: LinkParamsProps;
        authenticatedHref?: string;
        authenticatedParams?: LinkParamsProps;
        shallowBack?: boolean;
    } & Omit<LinkProps, 'params'>
> = ({
    href,
    params,
    children,
    authenticatedParams,
    shallowBack = false,
    authenticatedHref = 'ui_services_list',
    ...props
}) => {
    const router = useRouter();
    let { nextRoute, backTo, ...query } = router.query;
    const authedUser = useSelector((state: RootState) => state.auth.user);
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    const backToNew = useMemo(() => {
        if (shallowBack && backTo) {
            return backTo as string;
        }
        return RouteHref(nextRoute as string, currentLanguage, query);
    }, [nextRoute, currentLanguage, query]);
    const extraQuery = useMemo(() => {
        let queryNew = {};
        if ('returnTo' in query) {
            queryNew = { ...queryNew, returnTo: query.returnTo };
        }
        return queryNew;
    }, [query]);
    if (authedUser) {
        return (
            <Link
                href={authenticatedHref}
                params={authenticatedParams}
                {...props}
            >
                {children}
            </Link>
        );
    }
    return (
        <Link
            href={href}
            params={{ backTo: backToNew, ...extraQuery, ...params }}
            {...props}
        >
            {children}
        </Link>
    );
};

export default LinkToAuthenticate;
