import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        firstname: Yup.string()
            .max(
                64,
                t('schema.profile.firstname.max', {
                    defaultValue:
                        'Laukelis gali turėti daugiausiai 64 simbolius.',
                }),
            ),
        lastname: Yup.string()
            .max(
                64,
                t('schema.profile.lastname.max', {
                    defaultValue:
                        'Laukelis gali turėti daugiausiai 64 simbolius.',
                }),
            ),
        address: Yup.string()
            .max(
                64,
                t('schema.profile.address.max', {
                    defaultValue:
                        'Laukelis gali turėti daugiausiai 64 simbolius.',
                }),
            ),
        city: Yup.string()
            .max(
                64,
                t('schema.profile.city.max', {
                    defaultValue:
                        'Laukelis gali turėti daugiausiai 64 simbolius.',
                }),
            ),
        phone: Yup.string()
            .max(
                15,
                t('schema.profile.phone.max', {
                    defaultValue:
                        'Laukelis privalo būti sudarytas daugiausiai iki 15 simbolių.',
                }),
            ),
        country: Yup.number()
            .required(
                t('schema.profile.country.required', {
                    defaultValue: 'Pasirinkimas privalomas.',
                }),
            )
            .min(
                1,
                t('schema.profile.country.required', {
                    defaultValue: 'Pasirinkimas privalomas.',
                }),
            ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
