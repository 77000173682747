import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        email: Yup.string().required(
            t('form.errors.validation.email.required', {
                defaultValue: 'Laukelis privalomas.',
            }),
        ),
        password: Yup.string()
            .required(
                t('form.errors.validation.password.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .min(
                3,
                t('form.errors.validation.password.min', {
                    defaultValue:
                        'Slaptažodis privalo būti sudarytas mažiausiai iš 3 simbolių.',
                }),
            ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
