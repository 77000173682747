import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from '@root/utils/ValidationSchemas/Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        twoAuthCode: Yup.string()
            .required(
                t('schema.twoAuth.twoAuthCode.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
