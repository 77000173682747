import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SchemaType } from './Index';

const Schema = (): SchemaType => {
    const { t } = useTranslation('validation');
    return {
        email: Yup.string()
            .required(
                t('schema.passwordReset.email.required', {
                    defaultValue: 'Laukelis privalomas.',
                }),
            )
            .email(
                t('schema.passwordReset.email.email', {
                    defaultValue:
                        'Privalote nurodyti tinkama el. pašto adresą.',
                }),
            ),
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const init = () => Yup.object(Schema());

export { init };

export default Schema;
